import React from "react";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list' // a plugin!

export default function EventsListv2(props) {
  const events = props.events;
  
  class EventsCalendar2 extends React.Component {
    
    render() {
      return (
        <FullCalendar
          plugins={[ listPlugin ]}
          initialView="listMonth"
          events={events}
          timeZone='local'
          nextDayThreshold='09:00:00'
          nowIndicator='false'
          themeSystem="bootstrap5"
          headerToolbar='false'
          height={300}
          />
        )
      }
    }

  if (props.events) {
    return <EventsCalendar2 /> 
  }
    
};