import React from "react";
import { useHttpClient } from "../../../shared/hooks/http-hook";

import ErrorModal from "../../../shared/components/UIElements/errorModal/ErrorModal";
import LoadingSpinner from "../../../shared/components/UIElements/loadingSpinner/LoadingSpinner";
import { Card } from 'primereact/card';
import LeagueCardWidget from "../../../shared/components/leagueCardWidget/LeagueCardWidget";
import EventsListv2 from "../../components/tabs/season/schedule/EventsListv2";

const dummyEventData = [
  {
    title: 'Tier 1: Bahrain GP',
    start: '2022-12-12T21:30:00',
  },
  {
    title: 'Tier 2: Bahrain GP',
    start: '2022-12-14T20:00:00',
    backgroundColor: 'green',
    borderColor: 'green'
  },
  {
    title: 'Tier 1: Bahrain GP',
    start: '2022-12-19T21:30:00',
  },
  {
    title: 'Tier 2: Bahrain GP',
    start: '2022-12-21T20:00:00',
    backgroundColor: 'green',
    borderColor: 'green'
  },
]

const Overview = props => {
    const { isLoading, error, clearError } = useHttpClient();

    const loadedLeague = props.league;
    const loadedEvents = props.events;

    const eventDates = props.events.map((event) => {
      let date = new Date(event.properties.start).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
      return {
        track: event.properties.title,
        date: date
      };
    });

    let nextRace;
    if(eventDates.length > 0) {
      nextRace = `${eventDates[0].date} at ${eventDates[0].track}`
    } else {
      nextRace = 'No Upcoming Events'
    };

    let currentDate = new Date();

    let scheduledEvents = loadedEvents.filter(event => event.properties.start[0] > currentDate);

    if (isLoading) {
      return (
        <div className="center">
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <React.Fragment>
        <ErrorModal error={error} onClear={clearError} />
        {loadedLeague && (
          <div>

            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
              <div>
                  <div className="font-medium text-3xl text-900">{loadedLeague.properties.title}</div>
                  <div className="flex align-items-center text-800 flex-wrap">
                      <div className="mr-5 flex align-items-center mt-3">
                          <i className="pi pi-users mr-2"></i>
                          <span>{loadedLeague.members.length} Members</span>
                      </div>
                      <div className="mr-5 flex align-items-center mt-3">
                          <i className="pi pi-briefcase mr-2"></i>
                          <span>{loadedLeague.staff.length} Staff</span>
                      </div>
                      <div className="mr-5 flex align-items-center mt-3">
                          <i className="pi pi-globe mr-2"></i>
                          <span>{loadedLeague.series.length} Series</span>
                      </div>
                      <div className="mr-5 flex align-items-center mt-3">
                          <i className="pi pi-envelope mr-2"></i>
                          <span>{loadedLeague.events.length} Messages</span>
                      </div>
                      <div className="mr-5 flex align-items-center mt-3">
                          <i className="pi pi-calendar mr-2"></i>
                          <span>{scheduledEvents} Scheduled Events</span>
                      </div>
                      <div className="flex align-items-center mt-3">
                          <i className="pi pi-calendar mr-2"></i>
                          <span>Next Race: {nextRace}</span>
                      </div>
                  </div>
              </div>
            </div>

            <Card subTitle='UPCOMING EVENTS:' style={{margin:'2em', backgroundColor:'rgb(29,30,46,0.5)'}} >
              <EventsListv2 events={dummyEventData}/>
            </Card>

            <Card subTitle='RECENT WINNERS:' style={{margin:'2em', backgroundColor:'rgb(29,30,46,0.5)', display:'flex', flexDirection:'row'}} >
              <div style={{display:'flex', flexDirection:'row'}}>
                <LeagueCardWidget 
                  title="Tier 1" 
                  subtitle="Callum Smith" 
                  description="Winner of the Brazilian GP with Ferrari on October 1st, 2022." 
                  image="https://images.pexels.com/photos/3777943/pexels-photo-3777943.jpeg"
                  />
                <LeagueCardWidget 
                  title="Tier 2" 
                  subtitle="Barkley Summerlin" 
                  description="Winner of the Miami GP with Alpha Tauri on October 19th, 2022." 
                  image="https://images.pexels.com/photos/3767392/pexels-photo-3767392.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  />
              </div>
            </Card>

            <Card subTitle='STANDINGS:' style={{margin:'2em', backgroundColor:'rgb(29,30,46,0.5)', display:'flex', flexDirection:'row'}} >Coming Soon...</Card>

          </div>
        )}
      </React.Fragment>
    );
};

export default Overview;