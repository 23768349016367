import React, { useState } from "react";

import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';

import { Delete } from "../../modals/season/Delete";

export default function Overview(props) {

  const [loadedDivision, setLoadedDivision] = useState(props.division);
  const [loadedSeason, setLoadedSeason] = useState(props.season);

  let recruitment;
    if (loadedSeason.properties.isRecruiting === true) {
      recruitment = "Actively Recruiting"
    } else {
      recruitment = "Not Recruiting"
    }

  const footer = (
    <span>
        <Delete season={loadedSeason} />
    </span>
  );

  const getLeadingDriverAndTeam = () => {

    var holder = {};
    var team = [];
    const drivers = loadedSeason.drivers.map((driver) => { return { team: driver.team, points: driver.points }});

    drivers.forEach(function(d) {
      if (holder.hasOwnProperty(d.team)) {
        holder[d.team] = holder[d.team] + d.points;
      } else {
        holder[d.team] = d.points;
      }
    }); 

    for (var prop in holder) {
      team.push({ team: prop, points: holder[prop] });
    };

    return {
      driver: loadedSeason.drivers[0].username,
      team: team[0].team
    }
  };

  const leaders = getLeadingDriverAndTeam();

  return (
    <Card 
      title={`Season: ${loadedSeason.properties.identifier}`} 
      subTitle={`Division: ${loadedDivision.properties.title}`} 
      style={{ padding:'5px' }} 
      footer={footer} >
        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
          <div>

            <div className="flex align-items-center text-700 flex-wrap">
              <div className="mr-5 flex align-items-center mt-3">
                <i className="pi pi-users mr-2"></i>
                <span>{loadedSeason.drivers.length} Drivers</span>
              </div>
              <div className="mr-5 flex align-items-center mt-3">
                <i className="pi pi-globe mr-2"></i>
                <span>{recruitment}</span>
              </div>
              <div className="flex align-items-center mt-3">
                <i className="pi pi-clock mr-2"></i>
                <span>{loadedSeason.properties.status}</span>
              </div>
            </div>

            <br/>

            <div className="flex align-items-center text-700 flex-wrap">
              <div className="mr-5 flex align-items-center mt-3">
                <span>Leading Driver: {leaders.driver}</span>
              </div>
            </div>

            <div className="flex align-items-center text-700 flex-wrap">
              <div className="mr-5 flex align-items-center mt-3">
                <span>Leading Team: {leaders.team}</span>
              </div>
            </div>

          </div>
        </div>
      <br/>
      <Divider/>
    </Card>
  );
};