import React from 'react';

import { Card } from 'primereact/card';
import { Avatar } from 'primereact/avatar';
import { Divider } from 'primereact/divider';

import './LeagueCardWidget.css';

const socialIconContainerStyle = { fontSize: '1.5em', padding: '5px'};
const socialIconStyle = { margin: ' 0px 10px' };

const LeagueCardWidget = (props) => {

  const header = () => {
            
    return (
      <div className='card-widget_header'>
        <h2>{props.title}</h2>
        <Divider/>
        <div style={{display:'flex', alignItems:'center', width:'100%', justifyContent:'flex-start', marginLeft:'1rem'}}>
          <Avatar image={props.image} label="Avatar" shape="circle" className="card-widget_avatar mr-2"/>
          <h4>{props.subtitle}</h4>
        </div>
        <Divider />
      </div>
    )
  };

  return (
    <Card subTitle={props.description} className="card-widget_container" header={header}>
      
    </Card>
  );
};

export default LeagueCardWidget;