export const DUMMY_LEAGUE_ROLES = [
  { label: "Staff", value: "staff" },
  { label: "Member", value: "member" }
];

export const DUMMY_REGIONS = [
  { label: "North America", value: "North America" },
  { label: "South America", value: "South America" },
  { label: "Africa", value: "Africa" },
  { label: "Europe", value: "Europe" },
  { label: "Asia", value: "Asia" },
  { label: "Middle East", value: "Middle East" },
  { label: "Australia", value: "Australia" },
  { label: "Central America", value: "Central America" },
];

export const DUMMY_STATES = [
  { name: 'Alabama', abbreviation: 'AL'},
  { name: 'Alaska', abbreviation: 'AK'},
  { name: 'American Samoa', abbreviation: 'AS'},
  { name: 'Arizona', abbreviation: 'AZ'},
  { name: 'Arkansas', abbreviation: 'AR'},
  { name: 'California', abbreviation: 'CA'},
  { name: 'Colorado', abbreviation: 'CO'},
  { name: 'Connecticut', abbreviation: 'CT'},
  { name: 'Delware', abbreviation: 'DE'},
  { name: 'District of Columbia', abbreviation: 'DC'},
  { name: 'Federated States of Micronesia', abbreviation: 'FM'},
  { name: 'Florida', abbreviation: 'FL'},
  { name: 'Georgia', abbreviation: 'GA'},
  { name: 'Guam', abbreviation: 'GU'},
  { name: 'Hawaii', abbreviation: 'HI'},
  { name: 'Idaho', abbreviation: 'ID'},
  { name: 'Illinois', abbreviation: 'IL'},
  { name: 'Indiana', abbreviation: 'IN'},
  { name: 'Iowa', abbreviation: 'IA'},
  { name: 'Kansas', abbreviation: 'KS'},
  { name: 'Kentucky', abbreviation: 'KY'},
  { name: 'Louisiana', abbreviation: 'LA'},
  { name: 'Maine', abbreviation: 'ME'},
  { name: 'Marshall Islands', abbreviation: 'MH'},
  { name: 'Maryland', abbreviation: 'MD'},
  { name: 'Massachusetts', abbreviation: 'MA'},
  { name: 'Michigan', abbreviation: 'MI'},
  { name: 'Minnesota', abbreviation: 'MN'},
  { name: 'Mississippi', abbreviation: 'MS'},
  { name: 'Missouri', abbreviation: 'MO'},
  { name: 'Montana', abbreviation: 'MT'},
  { name: 'Nebraska', abbreviation: 'NE'},
  { name: 'Nevada', abbreviation: 'NV'},
  { name: 'New Hampshire', abbreviation: 'NH'},
  { name: 'New Jersey', abbreviation: 'NJ'},
  { name: 'New Mexico', abbreviation: 'NM'},
  { name: 'New York', abbreviation: 'NY'},
  { name: 'North Carolina', abbreviation: 'NC'},
  { name: 'North Dakota', abbreviation: 'ND'},
  { name: 'Northern Mariana Islands', abbreviation: 'MP'},
  { name: 'Ohio', abbreviation: 'OH'},
  { name: 'Oklahoma', abbreviation: 'OK'},
  { name: 'Oregon', abbreviation: 'OR'},
  { name: 'Palau', abbreviation: 'PW'},
  { name: 'Pennsylvania', abbreviation: 'PA'},
  { name: 'Puerto Rico', abbreviation: 'PR'},
  { name: 'Rhode Island', abbreviation: 'RI'},
  { name: 'South Carolina', abbreviation: 'SC'},
  { name: 'South Dakota', abbreviation: 'SD'},
  { name: 'Tennessee', abbreviation: 'TN'},
  { name: 'Texas', abbreviation: 'TX'},
  { name: 'Utah', abbreviation: 'UT'},
  { name: 'Vermont', abbreviation: 'VT'},
  { name: 'Virgin Islands', abbreviation: 'VI'},
  { name: 'Virginia', abbreviation: 'VA'},
  { name: 'Washington', abbreviation: 'WA'},
  { name: 'West Virginia', abbreviation: 'WV'},
  { name: 'Wisconsin', abbreviation: 'WI'},
  { name: 'Wyoming', abbreviation: 'WY' }
]

export const DUMMY_COUNTRIES = [
  {country_name:"Afghanistan",country_abr:"AFG",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Aland Islands",country_abr:"ALA",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Albania",country_abr:"ALB",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Algeria",country_abr:"DZA",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"American Samoa",country_abr:"ASM",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Andorra",country_abr:"AND",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Angola",country_abr:"AGO",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Anguilla",country_abr:"AIA",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Antarctica",country_abr:"ATA",continent_name:"Antarctica",continent_abr:"AN"},
  {country_name:"Antigua and Barbuda",country_abr:"ATG",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Argentina",country_abr:"ARG",continent_name:"South America",continent_abr:"SA"},
  {country_name:"Armenia",country_abr:"ARM",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Aruba",country_abr:"ABW",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Australia",country_abr:"AUS",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Austria",country_abr:"AUT",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Azerbaijan",country_abr:"AZE",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Bahamas",country_abr:"BHS",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Bahrain",country_abr:"BHR",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Bangladesh",country_abr:"BGD",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Barbados",country_abr:"BRB",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Belarus",country_abr:"BLR",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Belgium",country_abr:"BEL",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Belize",country_abr:"BLZ",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Benin",country_abr:"BEN",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Bermuda",country_abr:"BMU",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Bhutan",country_abr:"BTN",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Bolivia",country_abr:"BOL",continent_name:"South America",continent_abr:"SA"},
  {country_name:"Bonaire, Sint Eustatius and Saba",country_abr:"BES",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Bosnia and Herzegovina",country_abr:"BIH",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Botswana",country_abr:"BWA",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Bouvet Island",country_abr:"BVT",continent_name:"Antarctica",continent_abr:"AN"},
  {country_name:"Brazil",country_abr:"BRA",continent_name:"South America",continent_abr:"SA"},
  {country_name:"British Indian Ocean Territory",country_abr:"IOT",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Brunei Darussalam",country_abr:"BRN",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Bulgaria",country_abr:"BGR",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Burkina Faso",country_abr:"BFA",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Burundi",country_abr:"BDI",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Cambodia",country_abr:"KHM",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Cameroon",country_abr:"CMR",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Canada",country_abr:"CAN",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Cape Verde",country_abr:"CPV",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Cayman Islands",country_abr:"CYM",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Central African Republic",country_abr:"CAF",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Chad",country_abr:"TCD",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Chile",country_abr:"CHL",continent_name:"South America",continent_abr:"SA"},
  {country_name:"China",country_abr:"CHN",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Christmas Island",country_abr:"CXR",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Cocos (Keeling) Islands",country_abr:"CCK",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Colombia",country_abr:"COL",continent_name:"South America",continent_abr:"SA"},
  {country_name:"Comoros",country_abr:"COM",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Congo",country_abr:"COG",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Congo, Democratic Republic of the Congo",country_abr:"COD",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Cook Islands",country_abr:"COK",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Costa Rica",country_abr:"CRI",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Cote D'Ivoire",country_abr:"CIV",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Croatia",country_abr:"HRV",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Cuba",country_abr:"CUB",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Curacao",country_abr:"CUW",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Cyprus",country_abr:"CYP",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Czech Republic",country_abr:"CZE",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Denmark",country_abr:"DNK",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Djibouti",country_abr:"DJI",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Dominica",country_abr:"DMA",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Dominican Republic",country_abr:"DOM",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Ecuador",country_abr:"ECU",continent_name:"South America",continent_abr:"SA"},
  {country_name:"Egypt",country_abr:"EGY",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"El Salvador",country_abr:"SLV",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Equatorial Guinea",country_abr:"GNQ",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Eritrea",country_abr:"ERI",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Estonia",country_abr:"EST",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Ethiopia",country_abr:"ETH",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Falkland Islands (Malvinas)",country_abr:"FLK",continent_name:"South America",continent_abr:"SA"},
  {country_name:"Faroe Islands",country_abr:"FRO",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Fiji",country_abr:"FJI",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Finland",country_abr:"FIN",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"France",country_abr:"FRA",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"French Guiana",country_abr:"GUF",continent_name:"South America",continent_abr:"SA"},
  {country_name:"French Polynesia",country_abr:"PYF",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"French Southern Territories",country_abr:"ATF",continent_name:"Antarctica",continent_abr:"AN"},
  {country_name:"Gabon",country_abr:"GAB",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Gambia",country_abr:"GMB",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Georgia",country_abr:"GEO",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Germany",country_abr:"DEU",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Ghana",country_abr:"GHA",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Gibraltar",country_abr:"GIB",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Greece",country_abr:"GRC",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Greenland",country_abr:"GRL",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Grenada",country_abr:"GRD",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Guadeloupe",country_abr:"GLP",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Guam",country_abr:"GUM",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Guatemala",country_abr:"GTM",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Guernsey",country_abr:"GGY",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Guinea",country_abr:"GIN",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Guinea-Bissau",country_abr:"GNB",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Guyana",country_abr:"GUY",continent_name:"South America",continent_abr:"SA"},
  {country_name:"Haiti",country_abr:"HTI",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Heard Island and Mcdonald Islands",country_abr:"HMD",continent_name:"Antarctica",continent_abr:"AN"},
  {country_name:"Holy See (Vatican City State)",country_abr:"VAT",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Honduras",country_abr:"HND",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Hong Kong",country_abr:"HKG",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Hungary",country_abr:"HUN",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Iceland",country_abr:"ISL",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"India",country_abr:"IND",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Indonesia",country_abr:"IDN",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Iran, Islamic Republic of",country_abr:"IRN",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Iraq",country_abr:"IRQ",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Ireland",country_abr:"IRL",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Isle of Man",country_abr:"IMN",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Israel",country_abr:"ISR",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Italy",country_abr:"ITA",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Jamaica",country_abr:"JAM",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Japan",country_abr:"JPN",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Jersey",country_abr:"JEY",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Jordan",country_abr:"JOR",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Kazakhstan",country_abr:"KAZ",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Kenya",country_abr:"KEN",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Kiribati",country_abr:"KIR",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Korea, Democratic People's Republic of",country_abr:"PRK",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Korea, Republic of",country_abr:"KOR",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Kosovo",country_abr:"XKX",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Kuwait",country_abr:"KWT",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Kyrgyzstan",country_abr:"KGZ",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Lao People's Democratic Republic",country_abr:"LAO",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Latvia",country_abr:"LVA",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Lebanon",country_abr:"LBN",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Lesotho",country_abr:"LSO",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Liberia",country_abr:"LBR",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Libyan Arab Jamahiriya",country_abr:"LBY",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Liechtenstein",country_abr:"LIE",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Lithuania",country_abr:"LTU",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Luxembourg",country_abr:"LUX",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Macao",country_abr:"MAC",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Macedonia, the Former Yugoslav Republic of",country_abr:"MKD",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Madagascar",country_abr:"MDG",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Malawi",country_abr:"MWI",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Malaysia",country_abr:"MYS",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Maldives",country_abr:"MDV",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Mali",country_abr:"MLI",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Malta",country_abr:"MLT",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Marshall Islands",country_abr:"MHL",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Martinique",country_abr:"MTQ",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Mauritania",country_abr:"MRT",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Mauritius",country_abr:"MUS",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Mayotte",country_abr:"MYT",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Mexico",country_abr:"MEX",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Micronesia, Federated States of",country_abr:"FSM",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Moldova, Republic of",country_abr:"MDA",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Monaco",country_abr:"MCO",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Mongolia",country_abr:"MNG",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Montenegro",country_abr:"MNE",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Montserrat",country_abr:"MSR",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Morocco",country_abr:"MAR",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Mozambique",country_abr:"MOZ",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Myanmar",country_abr:"MMR",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Namibia",country_abr:"NAM",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Nauru",country_abr:"NRU",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Nepal",country_abr:"NPL",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Netherlands",country_abr:"NLD",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Netherlands Antilles",country_abr:"ANT",continent_name:"North America",continent_abr:"NA"},
  {country_name:"New Caledonia",country_abr:"NCL",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"New Zealand",country_abr:"NZL",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Nicaragua",country_abr:"NIC",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Niger",country_abr:"NER",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Nigeria",country_abr:"NGA",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Niue",country_abr:"NIU",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Norfolk Island",country_abr:"NFK",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Northern Mariana Islands",country_abr:"MNP",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Norway",country_abr:"NOR",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Oman",country_abr:"OMN",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Pakistan",country_abr:"PAK",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Palau",country_abr:"PLW",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Palestinian Territory, Occupied",country_abr:"PSE",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Panama",country_abr:"PAN",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Papua New Guinea",country_abr:"PNG",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Paraguay",country_abr:"PRY",continent_name:"South America",continent_abr:"SA"},
  {country_name:"Peru",country_abr:"PER",continent_name:"South America",continent_abr:"SA"},
  {country_name:"Philippines",country_abr:"PHL",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Pitcairn",country_abr:"PCN",continent_name:"Oceania","contineuser.nt_code":"OC"},
  {country_name:"Poland",country_abr:"POL",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Portugal",country_abr:"PRT",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Puerto Rico",country_abr:"PRI",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Qatar",country_abr:"QAT",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Reunion",country_abr:"REU",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Romania",country_abr:"ROM",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Russian Federation",country_abr:"RUS",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Rwanda",country_abr:"RWA",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Saint Barthelemy",country_abr:"BLM",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Saint Helena",country_abr:"SHN",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Saint Kitts and Nevis",country_abr:"KNA",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Saint Lucia",country_abr:"LCA",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Saint Martin",country_abr:"MAF",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Saint Pierre and Miquelon",country_abr:"SPM",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Saint Vincent and the Grenadines",country_abr:"VCT",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Samoa",country_abr:"WSM",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"San Marino",country_abr:"SMR",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Sao Tome and Principe",country_abr:"STP",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Saudi Arabia",country_abr:"SAU",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Senegal",country_abr:"SEN",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Serbia",country_abr:"SRB",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Serbia and Montenegro",country_abr:"SCG",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Seychelles",country_abr:"SYC",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Sierra Leone",country_abr:"SLE",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Singapore",country_abr:"SGP",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Sint Maarten",country_abr:"SXM",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Slovakia",country_abr:"SVK",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Slovenia",country_abr:"SVN",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Solomon Islands",country_abr:"SLB",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Somalia",country_abr:"SOM",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"South Africa",country_abr:"ZAF",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"South Georgia and the South Sandwich Islands",country_abr:"SGS",continent_name:"Antarctica",continent_abr:"AN"},
  {country_name:"South Sudan",country_abr:"SSD",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Spain",country_abr:"ESP",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Sri Lanka",country_abr:"LKA",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Sudan",country_abr:"SDN",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Suricountry_name",country_abr:"SUR",continent_name:"South America",continent_abr:"SA"},
  {country_name:"Svalbard and Jan Mayen",country_abr:"SJM",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Swaziland",country_abr:"SWZ",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Sweden",country_abr:"SWE",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Switzerland",country_abr:"CHE",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"Syrian Arab Republic",country_abr:"SYR",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Taiwan, Province of China",country_abr:"TWN",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Tajikistan",country_abr:"TJK",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Tanzania, United Republic of",country_abr:"TZA",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Thailand",country_abr:"THA",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Timor-Leste",country_abr:"TLS",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Togo",country_abr:"TGO",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Tokelau",country_abr:"TKL",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Tonga",country_abr:"TON",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Trinidad and Tobago",country_abr:"TTO",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Tunisia",country_abr:"TUN",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Turkey",country_abr:"TUR",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Turkmenistan",country_abr:"TKM",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Turks and Caicos Islands",country_abr:"TCA",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Tuvalu",country_abr:"TUV",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Uganda",country_abr:"UGA",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Ukraine",country_abr:"UKR",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"United Arab Emirates",country_abr:"ARE",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"United Kingdom",country_abr:"GBR",continent_name:"Europe",continent_abr:"EU"},
  {country_name:"United States",country_abr:"USA",continent_name:"North America",continent_abr:"NA"},
  {country_name:"United States Minor Outlying Islands",country_abr:"UMI",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Uruguay",country_abr:"URY",continent_name:"South America",continent_abr:"SA"},
  {country_name:"Uzbekistan",country_abr:"UZB",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Vanuatu",country_abr:"VUT",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Venezuela",country_abr:"VEN",continent_name:"South America",continent_abr:"SA"},
  {country_name:"Viet Nam",country_abr:"VNM",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Virgin Islands, British",country_abr:"VGB",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Virgin Islands, U.s.",country_abr:"VIR",continent_name:"North America",continent_abr:"NA"},
  {country_name:"Wallis and Futuna",country_abr:"WLF",continent_name:"Oceania",continent_abr:"OC"},
  {country_name:"Western Sahara",country_abr:"ESH",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Yemen",country_abr:"YEM",continent_name:"Asia",continent_abr:"AS"},
  {country_name:"Zambia",country_abr:"ZMB",continent_name:"Africa",continent_abr:"AF"},
  {country_name:"Zimbabwe",country_abr:"ZWE",continent_name:"Africa",continent_abr:"AF"}
];

export const DUMMY_EXPERIENCE_LEVELS = [
  {value: 'Beginner'},
  {value: 'Average'},
  {value: 'Veteran'}
];

export const DUMMY_SKILL_LEVELS = [
  {value: 'Slow'},
  {value: 'Average'},
  {value: 'Fast'},
];

export const DUMMY_SIMS = [
  {label: 'Assetto Corsa', value: 'Assetto Corsa'},
  {label: 'Assetto Corsa Competizione', value: 'Assetto Corsa Competizione'},
  {label: 'Dirt Rally 2.0', value: 'Dirt Rally 2.0'},
  {label: 'Dirt 4', value: 'Dirt 4'},
  {label: 'Dirt 5', value: 'Dirt 5'},
  {label: 'F1 2021', value: 'F1 2021'},
  {label: 'F1 2022', value: 'F1 2022'},
  {label: 'Gran Turismo 7', value: 'Gran Turismo 7'},
  {label: 'iRacing', value: 'iRacing'},
  {label: 'MotoGP 21', value: 'MotoGP 21'},
  {label: 'MotoGP 22', value: 'MotoGP 22'},
  {label: 'Project Cars', value: 'Project Cars'},
  {label: 'Project Cars 2', value: 'Project Cars 2'}, 
];

export const DUMMY_TEAMS = [
  { Id: 1, name: 'McLaren', icon: 'mclaren.png' },
  { Id: 2, name: 'Ferrari' },
  { Id: 3, name: 'Red Bull Racing' },
  { Id: 4, name: 'Mercedes' },
  { Id: 5, name: 'Alpine' },
  { Id: 6, name: 'Alfa Romeo' },
  { Id: 7, name: 'Haas' },
  { Id: 8, name: 'AlphaTauri' },
  { Id: 9, name: 'Aston Martin' },
  { Id: 10, name: 'Williams' },
  { Id: 11, name: 'Reserve' },
  { Id: 11, name: 'Unassigned' },
];

export const DUMMY_FINISHING_POSITIONS = [
  { value: 0 },
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
  { value: 15 },
  { value: 16 },
  { value: 17 },
  { value: 18 },
  { value: 19 },
  { value: 20 },
  { value: 21 },
  { value: 22 },
];

export const DUMMY_SPRINT_POINTS_MODEL = [
  { value: 1 },
  { Id: 2, name: 'Ferrari' },
  { Id: 3, name: 'Red Bull Racing' },
  { Id: 4, name: 'Mercedes' },
  { Id: 5, name: 'Alpine' },
  { Id: 6, name: 'Alfa Romeo' },
  { Id: 7, name: 'Haas' },
  { Id: 8, name: 'AlphaTauri' },
  { Id: 9, name: 'Aston Martin' },
  { Id: 10, name: 'Williams' },
  { Id: 11, name: 'Reserve' },
  { Id: 11, name: 'Unassigned' },
];

export const DUMMY_RACE_POINTS_MODEL = [
  { value: 1 },
  { Id: 2, name: 'Ferrari' },
  { Id: 3, name: 'Red Bull Racing' },
  { Id: 4, name: 'Mercedes' },
  { Id: 5, name: 'Alpine' },
  { Id: 6, name: 'Alfa Romeo' },
  { Id: 7, name: 'Haas' },
  { Id: 8, name: 'AlphaTauri' },
  { Id: 9, name: 'Aston Martin' },
  { Id: 10, name: 'Williams' },
  { Id: 11, name: 'Reserve' },
  { Id: 11, name: 'Unassigned' },
];

export const DUMMY_STATUS = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false }
];

export const DUMMY_DRIVER_ROLES = [
  { label: 'Full-Time', value: 'Full-Time' },
  { label: 'Reserve', value: 'Reserve' },
  { label: 'Inactive', value: 'Inactive' },
  { label: 'Pending', value: 'Pending' },
];

export const DUMMY_ATTENDANCE_STATUS = [
  { label: 'Unknown' },
  { label: 'Attended' },
  { label: 'Called Out' },
  { label: 'Not Eligible' },
  { label: 'No Show' }
];

export const DUMMY_EVENT_STATUS = [{ label: 'scheduled' }, { label: 'complete' }, { label: 'active' }];

export const DUMMY_EVENTS = [
  { title: 'Bahrain', start: '2022-09-19T21:30:00.426-05:00', end: '2022-09-19T22:00:00.426-05:00', backgroundColor: 'white', status: 'complete', drivers: ['A', 'B', 'C', 'D'] },
  { title: 'Saudi Arabia', start: '2022-09-21T21:30:00-05:00', end: '2022-09-21T23:00:00-05:00', backgroundColor: 'yellow', status: 'scheduled', drivers: ['A', 'B', 'C', 'D'] },
  { title: 'Australia', start: '2022-09-26T21:30:00-05:00', end: '2022-09-26T23:00:00-05:00', backgroundColor: 'yellow', status: 'scheduled', drivers: ['A', 'B', 'C', 'D'] },
  { title: 'Imola', start: '2022-09-28T21:30:00-05:00', end: '2022-09-28T23:00:00-05:00', backgroundColor: 'yellow', status: 'scheduled', drivers: ['A', 'B', 'C', 'D'] }
];

export const DUMMY_EVENTS_DRIVERS = [
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } },
  { username: 'Maniac Evolved', team: 'Mercedes', attendance: 'confirmed', role: 'Full-Time', result: { qualifying: 2, grid: 2, position: 1, points: 25, fastestLap: false, finished: true, } }
];

export const DUMMY_EVENTS2 = [
  { status: 'complete', round: 1, track: 'Bahrain', start: '2022-09-19', end: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
  { status: 'complete', round: 2, track: 'Saudi Arabia', date: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
  { status: 'complete', round: 3, track: 'Australia', date: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
  { status: 'complete', round: 4, track: 'Imola', date: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
  { status: 'complete', round: 5, track: 'Miami', date: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
  { status: 'complete', round: 6, track: 'Spain', date: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
  { status: 'complete', round: 7, track: 'Monaco', date: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
  { status: 'scheduled', round: 8, track: 'Azerbaijan', date: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
  { status: 'scheduled', round: 9, track: 'Canada', date: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
  { status: 'scheduled', round: 10, track: 'Great Britain', date: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
  { status: 'scheduled', round: 11, track: 'Austria', date: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
  { status: 'scheduled', round: 12, track: 'France', date: '2022-09-19', startDate: '01/01/2022', endDate: '01/01/2022', time: '21:30', startTime: '21:30', endTime: '21:30', drivers: ['A', 'B', 'C', 'D'] },
];

export const DUMMY_INCIDENT_REPORTS = [
  {id: '1', submitter: 'Maniac Evolved', round: '1', lap: '14', description: 'He hit me!', offenderType: 'Driver', offender: 'FLRacer', evidence: 'none', resolved: 'true', outcome: 'FLRacer was not given any dinner.'},
  {id: '2', submitter: 'Buckles', round: '2', lap: '18', description: 'The track glitched', offenderType: 'Non-Driver', offender: 'Codemasters', evidence: 'Video', resolved: 'false', outcome: 'Pending.'},
  {id: '3', submitter: 'Radioactive', round: '3', lap: '1', description: 'JMH rejoined to the track in an unsafe manner', offenderType: 'Driver', offender: 'JMH', evidence: 'Video', resolved: 'true', outcome: '3 Penalty Points and 5 second penalty to JMH.'}
];

export const DUMMY_TRACKS = [
  {id: 1, label: 'Bahrain'},
  {id: 2, label: 'Saudi Arabia'},
  {id: 3, label: 'Australia'},
  {id: 4, label: 'Italy: Imola'},
  {id: 5, label: 'USA: Miami'},
  {id: 6, label: 'Spain'},
  {id: 7, label: 'Monaco'},
  {id: 8, label: 'Azerbaijan'},
  {id: 9, label: 'Canada'},
  {id: 10, label: 'Great Britain'},
  {id: 11, label: 'Austria'},
  {id: 12, label: 'France'},
  {id: 13, label: 'Hungary'},
  {id: 14, label: 'Belgium'},
  {id: 15, label: 'Netherlands'},
  {id: 16, label: 'Italy: Monza'},
  {id: 17, label: 'Singapore'},
  {id: 18, label: 'Japan'},
  {id: 19, label: 'USA: Austin'},
  {id: 20, label: 'Mexico'},
  {id: 21, label: 'Brazil'},
  {id: 22, label: 'Abu Dhabi'}
]